import React, { Component } from 'react';
import './App.scss';

import { BrowserRouter as Router } from 'react-router-dom';

import Routing from './views/Routing';


class App extends Component {
    render() {
        return (
            <Router>
                <Routing />
            </Router>
        );
    }
}

export default App;
