import React, { Component } from 'react';

import Nav from '../components/basic/Nav';
import Start from './Start';

class Main extends Component {
    render() {
        return (
            <div className='_app'>
                <Nav />
                <Start />
            </div>
        );
    }
}

export default Main;
