import React, { Component } from 'react';

import './TextField.scss';

class TextField extends Component {
    render() {
        return (
            <div
                className={
                    '_text-field ' +
                    (this.props.error && this.props.error.length > 0
                        ? '_text-field--is-error'
                        : '')
                }
            >
                
                <input
                    className='_text-field__input'
                    value={this.props.value}
                    onChange={(e => this.props.handleChange(e.target.value))}
                    onBlur={() => this.props.onBlur()}
                    onFocus={() => this.props.onFocus()}
                />
                <div className='_text-field__label'>{this.props.label}</div>
                <div className='_text-field__error'>{this.props.error}</div>
            </div>
        );
    }
}

export default TextField;
