import React, { Component } from 'react';

import './Register.scss';

class RegisterInfo extends Component {
    render() {
        return (
            <div className='_view__register'>
                <div className='_view__register__amount'>
                    {this.props.label}
                </div>
            </div>
        );
    }
}

export default RegisterInfo;
