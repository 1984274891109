import React, { Component } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import Main from './Main';
import Reservation from './Reservation';

class Routing extends Component {
    render() {
        return (
            <div className="router">
            <Switch>
                <Route
                    path='/rezerwacja/:reservationId'
                    component={Reservation}
                />
                <Route exact path='/' component={Main} />
                <Redirect to='/' />
            </Switch>
                
            </div>
        );
    }
}

export default Routing;
