import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import './Reservation.scss';

import moment from 'moment';

import appConfig from '../appConfig.json';

import Loader from '../components/basic/Loader';

class Reservation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            request: false,
            reservationExists: false,
            reservation: {
                reservationId: 0,
                reservationStatus: 0,
                paymentCheck: {},
                paymentList: []
            }
        };
    }

    componentDidMount() {
        this.setState({ request: true });

        fetch(
            appConfig.apiUrl +
                '/api/reservationStatus?reservationId=' +
                this.props.match.params.reservationId,
            {
                method: 'GET'
            }
        )
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.setState({
                    reservationExists: true,
                    reservation: {
                        reservationId: data.reservationId,
                        reservationStatus: data.status,
                        paymentCheck: data.paymentCheck,
                        paymentList: data.paymentsList
                    }
                });
            })
            .catch(e => {
                console.log('error');
                console.log(e);
            })
            .finally(() => {
                this.setState({ request: false });
            });
    }

    payWithPayU = () => {
        fetch(
            appConfig.apiUrl +
                '/api/paymentLink?reservationId=' +
                this.props.match.params.reservationId,
            {
                method: 'GET'
            }
        )
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.paymentUrl) {
                    window.location = data.paymentUrl;
                }
            })
            .catch(e => {
                console.log('error');
                console.log(e);
                alert('Wystąpił błąd podczas generowania płatności w PayU.')
            })
            .finally(() => {
                this.setState({ request: false });
            });
    };

    render() {
        let rezerwacja = <div className='_view__reservation__details' />;

        if (!this.state.request) {
            if (this.state.reservationExists) {
                let status = '';

                switch (this.state.reservation.reservationStatus) {
                    case 0:
                        status = 'do opłacenia';
                        break;

                    case 1:
                        status = 'opłacona';
                        break;

                    case 2:
                        status = 'anulowana';
                        break;

                    default:
                        status = '-';
                        break;
                }



                let platnosci = this.state.reservation.paymentList.map(
                    payment => (
                        <div className='_payments__payment__row' key={payment.paymentId}>
                            <div className='_payments__payment__date'>
                                {moment(payment.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                            <div className='_payments__payment__provider'>
                                {payment.providerType === 1
                                    ? 'PayU'
                                    : payment.providerType === 2
                                    ? 'przelew'
                                    : '-'}
                            </div>
                            <div className='_payments__payment__status'>
                                {payment.paymentStatus === 0
                                    ? 'Nowa płatność'
                                    : payment.paymentStatus === 1
                                    ? 'Płatność w realizacji'
                                    : payment.paymentStatus === 2
                                    ? 'Płatność odrzucona'
                                    : payment.paymentStatus === 3
                                    ? 'Płatność zaakceptowana'
                                    : '-'}
                            </div>
                        </div>
                    )
                );

                if(this.state.reservation.paymentList.length === 0){
                    platnosci = (<div className='_payments__payment__row'><div className='--full-width'>Brak płatności</div></div>)
                }

                let payuButton = <div />;

                if (
                    this.state.reservation.paymentCheck.succesPaymentCount ===
                        0 &&
                    this.state.reservation.paymentCheck
                        .processingPaymentCount === 0
                ) {
                    payuButton = (
                        <div
                            className='_button-light _reservation__payu-button'
                            onClick={() => this.payWithPayU()}
                        >
                            Zapłać z PayU
                        </div>
                    );
                }

                rezerwacja = (
                    <div className='_view__reservation__details'>
                        <h2>
                            Twoja rezerwacja numer&nbsp;
                            {this.state.reservation.reservationId}
                        </h2>
                        <h3>
                            Status rezerwacji: <b>{status}</b>
                        </h3>
                        <h3>
                            Opłata za udział w konferencji: <b>100zł</b>
                        </h3>
                        <div className='_view__reservation__payu-button'>
                            {payuButton}
                        </div>
                        <h3>W przypadku problemów z płatnością poprzez PayU prosimy o kontakt mailowy: <a href='mailto:biuro@venn.pl'>biuro@venn.pl</a></h3>
                        <div className='_view__reservation__payments'>
                        <div className='_payments__payment__row'>
                            <div className='_payments__payment__date'>
                                Data rozpoczęcia
                            </div>
                            <div className='_payments__payment__provider'>
                                Sposób płatności
                            </div>
                            <div className='_payments__payment__status'>
                                Status
                            </div>
                        </div>
                            {platnosci}
                        </div>
                    </div>
                );
            } else {
                rezerwacja = (
                    <div className='_view__reservation__details'>
                        <h2>Rezerwacja o takim numerze nie istnieje</h2>
                    </div>
                );
            }
        }

        const loader = this.state.request ? (
            <Loader label={'Ładowanie...'} />
        ) : (
            <div />
        );

        return (
            <div className='_app'>
                <nav className='_reservation-nav'>
                    <Link
                        to='/'
                        className='_button-light _reservation-nav__go-back__button'
                    >
                        <i className='material-icons'>keyboard_arrow_left</i>
                        Powrót
                    </Link>
                </nav>
                <main className='_view'>
                    <div className='_view__container _view__container--medium _view__container--bg'>
                        <div className='_view__start__poster'>
                            <div className='_view__start__poster__title'>
                                <span>Konferencja</span>
                                <br />
                                Techgenalia
                                <span>
                                    <br />
                                    11-12/04/2019, Warszawa
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='_view__container _view__container--medium _view__container--bg'>
                        {rezerwacja}
                        {/* <div className='_view__reservation__payments'>
                            <h3>Twoje płatności</h3>

                            <div className='_view__reservation__payment payment--header'>
                                <div className='_view__reservation__payment__counter'>
                                    LP
                                </div>
                                <div className='_view__reservation__payment__date'>
                                    Data
                                </div>
                                <div className='_view__reservation__payment__method'>
                                    Metoda płatności
                                </div>
                                <div className='_view__reservation__payment__status'>
                                    Status płatności
                                </div>
                            </div>
                            <div className='_view__reservation__payment'>
                                <div className='_view__reservation__payment__counter'>
                                    1
                                </div>
                                <div className='_view__reservation__payment__date'>
                                    2019/04/01 10:18:00
                                </div>
                                <div className='_view__reservation__payment__method'>
                                    PayU
                                </div>
                                <div className='_view__reservation__payment__status'>
                                    W trakcie
                                </div>
                            </div>
                        </div> */}
                        {loader}
                    </div>
                </main>
            </div>
        );
    }
}

export default Reservation;
