import React, { Component } from 'react';

import Register from './Register';
import RegisterInfo from './RegisterInfo';
import Loader from '../components/basic/Loader';

import Agenda from './Agenda';

import appConfig from '../appConfig.json';

class Start extends Component {
    constructor(props) {
        super(props);

        this.state = {
            request: false,
            loaded: false,
            registrationOpen: false,
            registrationAvailable: 0
        };
    }

    componentDidMount() {
        this.setState({ request: true });
        fetch(appConfig.apiUrl + '/api/conferenceStatus',{
            method: 'GET'
        })
            .then(response => {
                console.log(response);
                return response.json();
            })
            .then(data => {
                this.setState({
                    request: false,
                    loaded: true,
                    registrationOpen: data.isOpen,
                    registrationAvailable: data.available
                });
            })
            .catch(e => {
                console.log(e);
                this.setState({
                    request: false,
                    loaded: false
                });
            });
    }

    render() {
        let register = <Loader label={'Łączę z serwerem...'} />;

        if (this.state.loaded) {
            if (this.state.registrationOpen) {
                if (this.state.registrationAvailable > 0) {
                    register = <Register />;
                } else {
                    register = <RegisterInfo label={'Brak miejsc'} />;
                }
            } else {
                register = <RegisterInfo label={'Rejestracja zamknięta'} />;
            }
        } else {
            if (this.state.request) {
                register = <Loader label={'Ładuję...'} />;
            } else {
                register = <RegisterInfo label={'Wystąpił błąd serwera'} />;
            }
        }

        return (
            <main className='_view'>
                <div className='_view__container _view__container--medium _view__container--bg'>
                    <div className='_view__start__poster'>
                        <div className='_view__start__poster__title'>
                        Techgenalia
                                <span>
                                    <br />
                                    11-12/04/2019, Warszawa
                                </span>
                        </div>
                        <div className='_view__start__poster__images'>
                            <img
                                src='/assets/images/logotypy.png'
                                alt='logotypy'
                                className='img--logotypy-1'
                            />
                           
                        </div>
                    </div>
                </div>
                

                <div
                    className='_view__container _view__container--medium'
                    id='agenda'
                >
                    <div className='_view__start__title'>Agenda</div>
                </div>

                <div className='_view__container _view__container--medium _view__container--bg'>
                    <Agenda />
                </div>

                <div
                    className='_view__container _view__container--medium'
                    id='lokalizacja'
                >
                    <div className='_view__start__title'>Lokalizacja</div>
                </div>

                <div className='_view__container _view__container--medium _view__container--bg'>
                    <div className='_view__start__container__title'>Hotel</div>
                    <div className='_view__start__paragraph paragraph--bigger'>
                        Konferencja Techgenalia 2019 odbędzie się w hotelu:
                    </div>
                    <div className='_view__start__paragraph paragraph--bigger paragraph--bg'>
                        <span>
                            <b>
                                DoubleTree by Hilton Hotel &amp;&nbsp;Conference
                                Centre Warsaw
                            </b>
                            ,<br />
                            ul. Skalnicowa 21, <br />
                            04-797 Warszawa
                        </span>
                    </div>

                    <div className='_view__start__location__gmaps'>
                        <iframe
                            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9784.741206985822!2d21.1418793!3d52.1855044!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x408f7a1ace95c13!2sDoubleTree+by+Hilton+Hotel+%26+Conference+Centre+Warsaw!5e0!3m2!1spl!2spl!4v1551830331383'
                            className='_view__start__location__gmaps__iframe'
                            title='Mapa'
                        />
                    </div>
                </div>

                <div
                    className='_view__container _view__container--medium'
                    id='rejestracja'
                >
                    <div className='_view__start__title'>Rejestracja</div>
                </div>

                <div className='_view__container _view__container--medium _view__container--bg'>
                    <div className='_register'>{register}</div>
                </div>
            </main>
        );
    }
}

export default Start;
