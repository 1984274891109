import React from 'react';

import './Agenda.scss';

const Agenda = () => (
    <div className='_agenda'>
        <div className='_agenda__row'>
            <div className='_agenda__day'>Czwartek 11/04/2019</div>
        </div>
        <div className='_agenda__row'>
            <div className='_agenda__time'>11:00 - 12:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>
                    Rejestracja uczestników
                </div>
            </div>
        </div>
        <div className='_agenda__row'>
            <div className='_agenda__time'>12:00 - 13:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Lunch</div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>13:00 - 13:05</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Otwarcie, powitanie Uczestników
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Łukasz Wołczyk (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>13:05 - 13:30</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Aktualna oferta kontraktów serwisowych&nbsp;GE
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Piotr Witkowski (GE Healthcare), Tomasz Sypniewski (GE
                        Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>13:30 - 14:15</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Optymalizacja kosztów eksploatacji aparatury medycznej
                        /&nbsp;Oprogramowanie do zarządzania aparaturą medyczną
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Prof.&nbsp;SGH dr&nbsp;hab. Monika Raulinajtys-Grzybek
                        (Szkoła Główna Handlowa),
                        <br /> Przemysław Koza (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>14:15 - 14:30</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Jak wygląda dobra współpraca między serwisem
                        a&nbsp;Klientem?
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Klaudia Małuj (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>14:30 - 14:45</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Opowieści z&nbsp;pola -&nbsp;ciekawe przypadki
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Bartłomiej Galus (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>14:45 - 15:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Przerwa kawowa</div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>15:00 - 15:45</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Aktualne wyzwania pomiarowe w&nbsp;radiologii ze
                        szczególnym uwzględnieniem obowiązujących zmian
                        prawnych. Testy specjalistyczne w&nbsp;praktyce
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Monika Jędrzejewska, Bartosz Węckowski (Specjaliści
                        Fizyki Medycznej)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>15:45 - 17:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Stosowanie procedur zamówień publicznych przy
                        wykonywaniu usług serwisowych a&nbsp; działalność
                        i&nbsp;odpowiedzialność Szpitali. Incydenty medyczne.
                        Panel dyskusyjny
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Radca Prawny dr Małgorzata Dobrzyńska-Dąbska (MDKA)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>17:00 - 17:15</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Przerwa kawowa</div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>17:15 - 18:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Najnowsze wiadomości ze świata&nbsp;GE
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Specjaliści produktowi (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>18:00 - 19:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Przeglądy techniczne i&nbsp;pomoc zdalna. Pokaz pracy
                        inżynierów serwisowych
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Bartosz Ulatowski (GE&nbsp;Healthcare),
                        <br /> Piotr Witkowski (GE&nbsp;Healthcare),
                        <br /> Sebastian Nowakowski (GE&nbsp;Healthcare),
                        <br /> Dawid Ziarek (GE&nbsp;Healthcare),
                        <br /> Andrzej Łysyganicz (GE&nbsp;Healthcare),
                        <br /> Piotr Pietrzak (GE&nbsp;Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>20:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Uroczysta kolacja</div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__day'>Piątek 12/04/2019</div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>09:00 - 09:45</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Diagnostyka obrazowa
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Mgr&nbsp;inż. Witold Ponikło
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>09:45 - 10:45</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Integracja systemów IT w&nbsp;Szpitalu
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Dr n.&nbsp;med. Waldemar Łabędź
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>10:45 - 11:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Przerwa kawowa</div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>11:00 - 11:30</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Rok po wprowadzeniu RODO. Panel dyskusyjny
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Radca Prawny Agata Kamińska (MDKA)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>11:30 - 13:30</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Zasady postępowania o&nbsp;udzielenie zamówienia publicznego
                        w praktyce Szpitali na przykładzie orzecznictwa.
                        Kryteria oceny ofert – praktyka i&nbsp;rzeczywistość.
                        Wybór i&nbsp;skutki wyboru – odpowiedzialność Szpitala
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Radca Prawny dr Małgorzata Dobrzyńska-Dąbska (MDKA)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>13:30 - 14:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__lecture'>
                    <div className='_agenda__conent__lecture__title'>
                        Podsumowanie, wyniki ankiety i&nbsp;quizu. Pożeganie
                    </div>
                    <div className='_agenda__conent__lecture__speaker'>
                        Łukasz Wołczyk (GE Healthcare)
                    </div>
                </div>
            </div>
        </div>

        <div className='_agenda__row'>
            <div className='_agenda__time'>14:00</div>
            <div className='_agenda__content'>
                <div className='_agenda__content__label'>Lunch</div>
            </div>
        </div>
    </div>
);

export default Agenda;
