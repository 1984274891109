import React, { Component } from 'react';

import './Register.scss';

import CheckBox from '../components/form/CheckBox';
import TextField from '../components/form/TextField';

import Loader from '../components/basic/Loader';

import appConfig from '../appConfig.json';

import regulamin from '../assets/Regulamin.pdf';

import _ from 'lodash';

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formData: {
                firstName: {
                    value: '',
                    error: '',
                    isError: false
                },
                lastName: {
                    value: '',
                    error: '',
                    isError: false
                },
                email: {
                    value: '',
                    error: '',
                    isError: false
                },
                telephone: {
                    value: '',
                    error: '',
                    isError: false
                },
                workPlace: {
                    value: '',
                    error: '',
                    isError: false
                },
                license: {
                    value: '',
                    error: '',
                    isError: false
                },
                fv: {
                    value: false
                },
                nip: {
                    value: '',
                    error: '',
                    isError: false
                }
            },
            request: false,
            registerError: false
        };
    }

    handleInput = (value, field) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [field]: {
                    ...this.state.formData[field],
                    value: value
                }
            }
        });
        if (this.state.formData[field].isError) {
            this.checkFieldOnInput(field);
        }
    };

    checkFieldOnInput = _.debounce(field => {
        this.verifyField(field);
    }, 400);

    onBlur = field => {
        console.log('blur: ' + field);
        this.verifyField(field);
    };

    onFocus = field => {
        console.log('focus: ' + field);
    };

    isValidNip(nip) {
        if (typeof nip !== 'string') return false;

        nip = nip.replace(/[\ \-]/gi, '');

        let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        let sum = 0;
        let controlNumber = parseInt(nip.substring(9, 10));
        for (let i = 0; i < weight.length; i++) {
            sum += parseInt(nip.substring(i, i + 1)) * weight[i];
        }

        return sum % 11 === controlNumber;
    }

    checkField(field) {
        let regex;
        switch (field) {
            case 'firstName':
            case 'lastName':
            case 'workPlace':
                const value = this.state.formData[field].value;
                return value && value.length > 0 ? true : false;

            case 'nip':
                if (this.state.formData.fv.value) {
                    return this.isValidNip(this.state.formData[field].value);
                } else {
                    return true;
                }

            case 'email':
                regex = new RegExp(
                    '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
                );
                return regex.test(this.state.formData[field].value);

            case 'telephone':
                regex = new RegExp('^[0-9]{9}$');
                return regex.test(this.state.formData[field].value);

            default:
                break;
        }
    }

    setErrorToField(field, error, isError) {
        this.setState(prevState => {
            return {
                formData: {
                    ...prevState.formData,
                    [field]: {
                        ...prevState.formData[field],
                        error: error,
                        isError: isError
                    }
                }
            };
        });
    }

    verifyField(field) {
        switch (field) {
            case 'firstName':
            case 'lastName':
            case 'workPlace':
                if (this.checkField(field)) {
                    this.setErrorToField(field, '', false);
                } else {
                    this.setErrorToField(
                        field,
                        'Pole nie może być puste',
                        true
                    );
                }
                break;

            case 'email':
                if (this.checkField(field)) {
                    this.setErrorToField(field, '', false);
                } else {
                    this.setErrorToField(
                        field,
                        'Podaj poprawny adres e-mail',
                        true
                    );
                }
                break;

            case 'telephone':
                if (this.checkField(field)) {
                    this.setErrorToField(field, '', false);
                } else {
                    this.setErrorToField(field, 'Wpisz ciąg 9 cyfr', true);
                }
                break;

            case 'nip':
                if (this.checkField(field)) {
                    this.setErrorToField(field, '', false);
                } else {
                    this.setErrorToField(field, 'Podaj poprawny NIP', true);
                }
                break;

            default:
                break;
        }
    }

    submit = () => {
        console.log('submit');
        const fields = [
            'firstName',
            'lastName',
            'email',
            'telephone',
            'workPlace',
            'nip'
        ];

        let allOK = true;
        for (let i = 0; i < fields.length; i++) {
            this.verifyField(fields[i]);
            if (!this.checkField(fields[i])) {
                allOK = false;
            }
        }

        if (allOK) {
            this.setState(prevState => {
                return {
                    request: true
                };
            });

            const userData = {
                firstName: this.state.formData.firstName.value,
                lastName: this.state.formData.lastName.value,
                email: this.state.formData.email.value,
                telephone: this.state.formData.telephone.value,
                workPlace: this.state.formData.workPlace.value,
                fv: this.state.formData.fv.value,
                nip: this.state.formData.nip.value
            };

            console.log(userData);

            fetch(appConfig.apiUrl + '/api/enroll', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            })
                .then(response => response.json())
                .then(data => {
                    console.log('response ok');
                    console.log(data);
                    window.location = '/rezerwacja/'+data.registrationId;
                })
                .catch(e => {
                    console.log('error');
                    console.log(e);
                    this.setState({ registerError: true });
                });
        }
    };

    render() {
        const nipField = this.state.formData.fv.value ? (
            <TextField
                if={this.state.formData.fv.value}
                label='NIP'
                value={this.state.formData.nip.value}
                handleChange={value => this.handleInput(value, 'nip')}
                onBlur={() => this.onBlur('nip')}
                onFocus={() => this.onFocus('nip')}
                error={this.state.formData.nip.error}
            />
        ) : (
            ''
        );

        const loader = this.state.request ? (
            <Loader label={'Wysyłam...'} />
        ) : (
            <div />
        );

        if (this.state.registerError) {
            return (
                <div className='_view__register'>
                    <div className='_view__register__amount'>
                        Wystąpił błąd podczas rejestracji. <br />
                        Odśwież stronę i spróbuj ponownie.
                    </div>
                </div>
            );
        }
        return (
            <div className='_view__register'>
                <div className='_view__register__amount'>
                    Opłata za udział w konferencji: <b>100zł</b>
                </div>

                <TextField
                    label='Imię'
                    value={this.state.formData.firstName.value}
                    handleChange={value => this.handleInput(value, 'firstName')}
                    onBlur={() => this.onBlur('firstName')}
                    onFocus={() => this.onFocus('firstName')}
                    error={this.state.formData.firstName.error}
                />

                <TextField
                    label='Nazwisko'
                    value={this.state.formData.lastName.value}
                    handleChange={value => this.handleInput(value, 'lastName')}
                    onBlur={() => this.onBlur('lastName')}
                    onFocus={() => this.onFocus('lastName')}
                    error={this.state.formData.lastName.error}
                />

                <TextField
                    label='E-Mail'
                    value={this.state.formData.email.value}
                    handleChange={value => this.handleInput(value, 'email')}
                    onBlur={() => this.onBlur('email')}
                    onFocus={() => this.onFocus('email')}
                    error={this.state.formData.email.error}
                />

                <TextField
                    label='Telefon'
                    value={this.state.formData.telephone.value}
                    handleChange={value => this.handleInput(value, 'telephone')}
                    onBlur={() => this.onBlur('telephone')}
                    onFocus={() => this.onFocus('telephone')}
                    error={this.state.formData.telephone.error}
                />

                <TextField
                    label='Placówka'
                    value={this.state.formData.workPlace.value}
                    handleChange={value => this.handleInput(value, 'workPlace')}
                    onBlur={() => this.onBlur('workPlace')}
                    onFocus={() => this.onFocus('workPlace')}
                    error={this.state.formData.workPlace.error}
                />

                <CheckBox
                    label='Chcę otrzymać fv'
                    value={this.state.formData.fv.value}
                    handleInput={value => this.handleInput(value, 'fv')}
                />

                {nipField}

                <div className='_view__register__amount'>
                    <b>Zgody</b>&nbsp;- zatwierdzane wraz z rejestracją
                </div>

                <div className=''>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                    GE Healthcare z siedzibą w Warszawie zgodnie z ustawą z dn.
                    29 sierpnia 1997r. o ochronie danych osobowych (Dz. U. z
                    2002 r. Nr 101, poz. 926 z późn. zm.) w celach
                    marketingowych obejmujące przesyłanie informacji drogą
                    telefoniczną lub pocztową. Niniejsza zgoda obejmuje
                    przetwarzanie danych osobowych w przyszłości, o ile nie
                    zmieni się cel przetwarzania.
                    <br />
                    <br /> Oświadczam, że zostałam(em) poinformowana(y) o
                    przysługującym mi prawie dostępu do treści moich danych
                    osobowych oraz ich poprawiania, jak również prawie
                    wniesienia w każdym czasie sprzeciwu wobec ich
                    przetwarzania. Wyrażam zgodę na przesyłanie informacji
                    handlowych drogą elektroniczną przez Ge Healthcare z
                    siedzibą w Warszawie zgodnie z ustawą z dn. 18 lipca 2002
                    roku o świadczeniu usług drogą elektroniczną (Dz. U. z 2002
                    r. Nr 144, poz. 1204 z późn. zm.). <br />
                    <br />
                    Wobec wejścia w życie z dniem 25 maja 2018 roku
                    rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679
                    z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych
                    w związku z przetwarzaniem danych osobowych i w sprawie
                    swobodnego przepływu takich danych oraz uchylenia dyrektywy
                    95/46/WE - ogólne rozporządzenie o ochronie danych „RODO”
                    informujemy, iż na stronie internetowej www.venn.pl
                    znajdziecie Państwo politykę prywatności strony
                    internetowej. Administratorem danych osobowych
                    przetwarzanych w ramach prowadzonej działalności jest Venn
                    Krzysztof Gaik.<br />
                    <br />
                    <a href={regulamin} className='a-regulamin'>Regulamin</a>
                </div>

                <button
                    className='_view__register__submit'
                    onClick={() => this.submit()}
                >
                    Zarejestruj się <i className='material-icons'>send</i>
                </button>

                {loader}
            </div>
        );
    }
}

export default Register;
