import React, { Component } from 'react';
import './Nav.scss';

import $ from 'jquery';

import logoImg from '../../assets/logo/logo_ge.png';

class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navOpen: false
        };
    }

    toggleNav = () => {
        this.setState({ navOpen: !this.state.navOpen });
    };

    goTo = anchorId => {
        console.log('goTo: ' + anchorId);
        this.setState({ navOpen: false});

        $('html, body').animate({
            scrollTop: $(`#${anchorId}`).offset().top - 60
        }, 500, 'linear');
    };

    render() {
        return (
            <nav
                className={
                    '_nav ' + (this.state.navOpen ? '_nav--is-open' : '')
                }
            >
                <div className='_nav__left'>
                    <img
                        src={logoImg}
                        className='_nav__left__logo'
                        alt='GE Healthcare logo'
                    />
                </div>
                <div className='_nav__middle'>
                    <ul>
                        <li onClick={() => this.goTo('agenda')}>Agenda</li>
                        <li onClick={() => this.goTo('lokalizacja')}>
                            Lokalizacja
                        </li>
                        <li onClick={() => this.goTo('rejestracja')}>
                            Rejestracja
                        </li>
                    </ul>
                </div>
                <div className='_nav__right'>
                    <div
                        className='_nav__toggle'
                        onClick={() => this.toggleNav()}
                    >
                        <div className='_nav__toggle__bar' />
                    </div>
                </div>
            </nav>
        );
    }
}

export default Nav;
